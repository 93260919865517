footer {
	background-color: var(--footer-bg);
	color: var(--footer-text);
}

footer .main-row {
	background-color: var(--footer-bg) !important;
}
footer .col-4 {
	/* padding: 24px; */
}
.map-cont {
	border-radius: 8px;
	overflow: hidden;
}
.map-responsive {
	overflow: hidden;
	/* padding-bottom: 71.5%; */
	padding-bottom: 60.5%;
	/* padding-bottom: 75.25%; */
	position: relative;
	height: 0;
	border-radius: 8px;
}
.map-responsive iframe {
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	/* min-height: 600px; */
	position: absolute;
}

footer img {
	padding-bottom: 2rem;
}

div.ft-tel-links {
	display: flex;
	flex-direction: column;
}
div.last-el {
	padding-bottom: 0;
	line-height: 1.4rem;
}
.ft-tel-links a {
	padding-bottom: 0.4rem;
	color: var(--footer-text);
	font-weight: 700;
	letter-spacing: 0.15rem;
}
.ft-paragraph {
	padding-bottom: 1.26rem;
	color: var(--footer-text);
	font-size: 0.9rem;
	line-height: 1.4rem;
}
.ft-paragraph a {
	color: var(--footer-text);
}
.ft-head {
	/* font-size: 1.45rem; */
	font-size: 1.2rem;
	font-weight: 700;
	padding-bottom: 2rem;
	color: var(--footer-text);
}
.ft-head-cont p {
	color: var(--footer-text);
	max-width: 300px;
	font-size: 0.9rem;
}

.ft-legal {
	font-size: 0.8rem;
	text-align: left;
	/* margin-top: calc(-0.5 * var(--gap-l)); */
	/* margin-top: calc(-1 * var(--gap-l)); */
	margin-top: calc(-1 * var(--gap-m));
	padding: 50px 0;
	/* padding-left: var(--gutter-width); */
	margin-left: var(--gutter-width);
	margin-right: var(--gutter-width);
	color: silver;
	border-top: 1px solid #727171;
	/* margin-left: 50px; */
	/* margin-right: 50px; */
}
