body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  overflow-x: hidden;
}
.container {
  flex: 1;
}
.col-1 {
  width: 8.33%;
}
.col-2 {
  width: 16.66%;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.33%;
}
.col-5 {
  width: 41.66%;
}
.col-6 {
  width: 50%;
}
.col-7 {
  width: 58.33%;
}
.col-8 {
  width: 66.66%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.33%;
}
.col-11 {
  width: 91.66%;
}
.col-12 {
  width: 100%;
}
.col-b {
  width: 65%;
}
.col-s {
  width: 35%;
}
.gutter {
  /* width: var(--gutter-width); */
}
.main-row {
  display: flex;
  width: 100%;
  /* margin-top: var(--gap);
  margin-bottom: var(--gap); */
  padding-top: var(--gap-l);
  padding-bottom: var(--gap-l);
}
.main-row::after {
  display: block;
  clear: both;
  content: "";
}
.sub-row {
  display: flex;
  width: 100%;
  /* padding: 25px; */
  padding-left: var(--gutter-width);
  padding-right: var(--gutter-width);
}
.tab-content .sub-row {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-left: 30px;
  padding-right: 30px;
}
.tab-content .gutter {
  width: var(--gutter-width) !important;
}
.prod-img-cont {
  /* background-color: red !important; */
}
.prod-img-cont .gutter {
  width: 0 !important;
}
.prod-img-cont .sub-row {
  padding-left: calc(var(--gutter-width) - 1rem) !important;
  padding-right: calc(var(--gutter-width) - 1rem) !important;
}
/* .tab-content:nth-child(odd) .col-b {
	margin: 0;
	margin-top: -25px;
	margin-bottom: -25px;
} */
/* .col-b {
	margin: -25px;
} */
.sub-row::after {
  display: block;
  clear: both;
  content: "";
}
.cont-l,
.cont-r {
  /* max-width: 300px; */
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-bottom: 10rem;
}
.cont-l {
  /* margin-right: var(--cont-width); */
  padding-right: var(--cont-gap);
}
.cont-r {
  /* margin-left: var(--cont-width); */
  padding-left: var(--cont-gap);
}
.img-cont-l {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 0 var(--radius) var(--radius) 0;
  overflow: hidden;

  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 100%;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25),
    0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
}
.img-cont-r {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: var(--radius) 0 0 var(--radius);
  overflow: hidden;

  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 100%;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25),
    0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  border-radius: 8px 0 0 8px;
}
.side-img {
  width: 100%;
  height: 100%;

  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  /* z-index: -1; */
  object-fit: cover;

  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25),
    0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  border-radius: 0 8px 8px 0;
}
.primary {
  background-color: var(--primary);
  color: #fff;
  /* font-size: 1.125rem; */
  font-size: 1rem;
  /* padding: 1.2rem 2rem; */
  /* padding: 10px 12px; */
  padding: 10px;
  border-radius: 5px;
  border: 2px solid var(--primary);
  transition: background-color 0.1s ease-in, color 0.1s ease-in,
    border-color 0.1s ease-in;
}
.primary:hover {
  /* background-color: var(--bg-col); */
  background-color: transparent;
  color: var(--primary);
  border-color: var(--primary);
  img {
    filter: invert(51%) sepia(98%) saturate(4186%) hue-rotate(175deg)
      brightness(93%) contrast(101%);
  }
}
.secondary {
  background-color: var(--bg-col);
  color: var(--primary);
  border: 2px solid var(--bg-col);
  font-size: 1.125rem;
  /* padding: 1.2rem 2rem; */
  padding: 10px;
  border-radius: 5px;
  font-weight: 500;
  transition: background-color 0.1s ease-in, color 0.1s ease-in,
    border-color 0.1s ease-in;
}
.secondary:hover {
  /* background-color: var(--bg-col); */
  background-color: transparent;
  color: var(--bg-col);
  /* border-color: var(--bg-col); */
}

/* =========== CAROUSEL =================== */
.carousel {
  /* padding-left: var(--gutter-width);
	padding-right: var(--gutter-width); */
  width: 100%;
  height: 196px;
  /* background-color: pink; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
}
.swiper-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  /* background: rgb(255, 0, 0) !important;
	background: linear-gradient(
		90deg,
		rgba(255, 0, 0, 1) 0%,
		rgba(255, 255, 255, 0) 10%,
		rgba(255, 255, 255, 0) 90%,
		rgba(255, 0, 0, 1) 100%
	) !important; */
  margin-left: -100px !important;
  margin-right: -100px !important;
}
.swiper-slide {
  height: 196px;
  width: 280px;
  background-color: var(--bg-col);
  /* margin: 0 !important;
	margin: 0 10px; */
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border-color: #fff;
  border: 0 !important;
  box-sizing: border-box !important;
  /* background-size: 85%; */
  background-size: 70%;
}
.swiper-button-prev,
.swiper-button-next {
  color: var(--primary) !important;
  // position: absolute;
  // top: 0;
  /* background-color: var(--bg-col) !important; */
  border-radius: 6px !important;
  height: 50px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  /* box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25),
		0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03) !important; */
  /* box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5) !important; */
}
.swiper-button-prev {
  left: 0 !important;
}
.swiper-button-next {
  right: 0 !important;
}
.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 36px !important;
  /* padding: 10px !important; */
  width: 50px !important;
}

.hero {
  width: 100%;
  /* height: 60vh; */
  background: url("../assets/images/home/hero.jpg");
  background-position: right;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.hero > div,
.cta-box {
  background-color: var(--hero-card);
  max-width: 500px;
  /* margin-left: var(--gutter-width); */
  color: #fff;
  padding: var(--gap-m);
  margin-top: var(--gap-l);
  margin-bottom: var(--gap-l);
  border-radius: 8px;
  overflow: hidden;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.hero > div > h1 {
  text-transform: uppercase;
  font-weight: 900;
  padding-bottom: var(--gap-s);
  color: inherit;
  font-size: 3rem;
  line-height: 1.1;
}
.hero p {
  color: inherit;
  padding: 0;
  font-weight: 500;
}

/* =========== UTILITIES ========== */
.grey-sec {
  background-color: var(--grey-sec) !important;
  color: var(--pg) !important;
}
.text-center {
  text-align: center;
}
.center-offset {
  padding-left: var(--center-offset);
  padding-right: var(--center-offset);
}
.no-bpadding {
  padding-bottom: 0;
}
.no-tpadding {
  padding-top: 0;
}
.ml {
  margin-left: 25px;
}
.mr {
  margin-right: 25px;
}

.img-top {
  object-position: center top;
}
.neu-sec {
  background-color: var(--neu-bg);
}
.neu {
  box-shadow: -12px -12px 12px 0 var(--neu-top),
    12px 12px 12px 0 var(--neu-bottom);
}
/* =========== UTILITIES ========== */

.head {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: capitalize;
  padding-bottom: var(--gap-m);
}
.head h2 {
  color: var(--primary);
  font-size: 1.8rem;
  font-weight: 500;
}
.head div {
  width: 100px;
  height: 3px;
  background-color: var(--line);
  margin-top: 1rem;
}

.card-cont {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: var(--gap-l);
  margin-right: -10px;
  margin-left: -10px;
}
.card-cont a {
  text-decoration: none;
}
.card-cont a:focus {
  outline: none;
}
.card-cont a:focus .card {
  outline: none;
  box-shadow: 0 0 0 5px var(--primary);
}
.card {
  background-color: gray;
  background-position: center;
  background-size: 100%;
  width: 100%;
  width: 188px;
  /* height: 100%; */
  height: 120px;
  /* min-height: 150px; */
  margin: 10px;
  /* padding: 15px; */
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  text-align: center;
  font-weight: 700;
  text-transform: capitalize;

  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  /* background-blend-mode: multiply; */
  transition: background 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    transform 0.2s ease-in-out;
}
.card:hover {
  -webkit-box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.6);
  transform: translateY(-5px);
}
.card:focus {
  outline: none;
  box-shadow: 0 0 0 5px var(--primary);
}
.card > div {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* opacity: 0.5; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  transition: background 0.2s ease-in-out;
}
.card:hover {
  background-blend-mode: normal;
  background-size: 120%;
  color: transparent !important;
}
.card > div:hover {
  background-color: transparent;
}

.home-cta {
  text-align: center;
}
.home-cta > p {
  color: var(--bg-col);
  font-size: 1.25rem;
  font-weight: 300;
}
.home-cta strong {
  font-size: 2.25rem;
  font-weight: 700;
}
.home-cta a.secondary {
  display: inline-block;
  margin-bottom: 1rem;
  /* margin-top: 1.6rem; */
}

/* ==========  PRODUCTS PAGE ==========*/
.sec-hero {
  width: 100%;
  height: 150px;
}
.offset-card {
  background-color: var(--hero-card);
  padding: var(--gap-l);
  position: relative;
  top: var(--top-offset);
  margin-bottom: var(--top-offset);
}
.offset-card p {
  color: #fff;
}
.head.left {
  align-items: flex-start;
  padding-bottom: 0;
}
.head.left > h1 {
  /* font-size: 2.25rem; */
  font-size: 1.8rem;
  font-weight: 500;
  color: #000;
  padding-bottom: 0;
}
.head.left > div {
  background-color: #000;
}
h2.sub-head {
  /* font-size: 2.25rem; */
  font-size: 1.8rem;
  font-weight: 500;
  text-transform: capitalize;
  color: var(--primary);
  padding-bottom: var(--gap-s);
}

.gallery-img-cont {
  padding: 1rem;
}

.gallery-img {
  min-height: 200px;
  border-radius: 8px;
  /* box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25),
		0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03); */
}

.prod-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.prod-cta > p {
  font-size: 1.6rem;
  font-weight: 300;
  /* padding-bottom: 3rem; */
  padding-bottom: 1.5rem;
}
.prod-cta a.primary {
  margin-bottom: 16rem;
}

.to-top {
  display: flex;
  justify-content: flex-end;
}
.to-top a {
  display: flex;
  justify-content: center;
  align-items: center;
}
.to-top img {
  width: 24px;
  margin-right: 14px;
  filter: invert(1);
}

/* TAB CONTENT */

/* Style the tab */
/* .tab {
	overflow: hidden;
	border: 1px solid #ccc;
	background-color: #f1f1f1;
} */

/* Style the buttons that are used to open the tab content */
/* .tab button {
	background-color: inherit;
	float: left;
	border: none;
	outline: none;
	cursor: pointer;
	padding: 14px 16px;
	transition: 0.3s;
} */

/* Change background color of buttons on hover */
/* .tab button:hover {
	background-color: #ddd;
} */

/* Create an active/current tablink class */
.card.active,
.card.active > div,
.card.active > div:hover {
  background-color: var(--primary);
  background-image: none !important;
  color: #fff !important;
}

/* Style the tab content */
.tab-content {
  display: none;
  background-color: var(--grey-sec);
  animation: fadeEffect 1s;
}

/* .tabcontent {
	animation: fadeEffect 1s;
} */
@keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* =========== CONTACT PAGE ================= */

.contact-head.left > h1 {
  padding-bottom: 2rem;
}

form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-bottom: 10rem;
}
form > * {
  margin-bottom: 2rem;
  padding: 1.2rem;
  /* font-size: 1.125rem; */
  font-size: 1rem;
  font-weight: 300;
  border: 0;
  width: 100%;
  border-radius: 5px;
  color: #000;
}
form > textarea {
  max-width: 100%;
}
// form input[type="submit"] {
//   font-weight: 500;
//   width: fit-content;
//   cursor: pointer;
// }
button[type="submit"] {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  width: max-content;
  cursor: pointer;
}

.contact-cont {
  background-image: url(../assets/images/contact/form-bg.jpg);

  div.main-row {
    background-color: unset;
  }
}

/* ========================= RESPONSIVE =============================*/

@media all and (max-width: 1024px) {
  :root {
    --gutter-width: 6%;
    --center-offset: 0px;
  }
  [class*="col-"] {
    width: 100%;
    margin-bottom: 36px;
  }
  .cont-l,
  .cont-r {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
    border-radius: 8px;
  }
  .main-row,
  .sub-row {
    flex-direction: column;
  }
  .main-row {
    padding-left: var(--gutter-width);
    padding-right: var(--gutter-width);
  }
  .img-cont-l,
  .img-cont-r {
    /* height: 200px; */
    min-height: 40vh;
  }
  .hero > div {
    margin: 2rem;
  }
  .carousel {
    padding-left: 0;
    padding-right: 0;
  }
  .card-cont {
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .card {
    width: 100%;
    max-width: none;
  }
  .tab-links {
    max-width: none;
    min-width: 100%;
    height: 80px;
  }
}

@media (prefers-color-scheme: dark) {
}
