@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;900&display=swap");

/* --- REMOVING DEFAULT --- */

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  text-decoration: none;
}

/* --- COLORS --- */

/* Default theme colors */
:root {
  --primary: #008dd2;
  --bg-col: #fff;
  --scroll-bg: #ececec;
  --primary-hover: #0976ac;
  --spinner-bg: var(--primary);

  /*Navigation*/
  --nav-bg: #fff;
  --nav-links: #555;
  --nav-links-hover: var(--primary);
  --nav-burger: #555;

  /*Footer*/
  --footer-bg: #3f3f3f;
  --footer-text: #ffffffd9;

  /* Style */
  --hero-card: #008cd2be;
  --grey-sec: #f3f3f3;
  --line: #9d9e9e;

  /*Text*/
  --heading: #000;
  --body: #000;
  --pg: #1b1b1bd9;
  --pw: #000000d9;

  /*Layout*/
  /* --gutter-width: 15%; */
  /* --gutter-width: 250px; */
  --gutter-width: 15%;
  --gap-s: 1.5rem;
  --gap-m: 2.5rem;
  --gap-l: 4.5rem;
  --top-offset: -180px;
  --center-offset: 14%;
  --cont-gap: 100px;
}

/* Dark theme colors */
// @media (prefers-color-scheme: dark) {
// 	:root {
// 		--primary: #fdc124;
// 		--bg-col: #fff;
// 		--scroll-bg: #ececec;
// 		--primary-hover: #ecb62e;

// 		--neu-bg: #f2f2f2;
// 		--neu-top: rgba(255, 255, 255, 1);
// 		--neu-bottom: rgba(0, 0, 0, 0.3);

// 		/*Navigation*/
// 		--nav-bg: #fdc124;
// 		--nav-links: #fff;
// 		--nav-links-hover: #000;
// 		--nav-burger: #fff;

// 		/*Text*/
// 		--heading: #000;
// 		--body: #000;
// 	}
// }

/* --- CUSTOMIZING SCROLLBAR --- */

/* The emerging W3C standard
   that is currently Firefox-only */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--primary) var(--scroll-bg);
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 12px;
}
*::-webkit-scrollbar-track {
  background: var(--scroll-bg);
}
*::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 4px;
  border: 2px solid var(--scroll-bg);
}

/* --- TYPOGRAPHY --- */

nav,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  color: var(--heading);
}

body {
  font-family: "Montserrat", sans-serif;
  color: var(--body);
}

.grey-text {
  color: var(--grey-text);
}

form,
input,
select,
option,
textarea {
  font-family: "Montserrat", sans-serif;
}

/* --- FONT SIZES --- */

nav {
  font-size: 16px;
  font-weight: 500;
}
p {
  /* font-size: 1.25rem; */
  font-size: 1rem;
  padding-bottom: var(--gap-s);
  line-height: 1.8;
  font-weight: 300;
  color: var(--pw);
}
h1,
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.5rem;
  padding-bottom: 16px;
}
h1 {
  padding-bottom: 1.5rem;
}

/* --- GLOBAL BACKGROUND COLOR --- */

body {
  background-color: var(--bg-col);
}

::-moz-selection {
  /* Code for Firefox */
  color: var(--bg-col);
  background: var(--primary);
}

::selection {
  color: var(--bg-col);
  background: var(--primary);
}

/* --- GLOBAL ELEMENTS --- */

/* Image inside div */

.image-cont {
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.image-cont > img {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  /* z-index: -1; */
  object-fit: cover;
}

.spinner-cont {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;

  position: fixed;
  top: 0;
  left: 0;

  background-color: var(--spinner-bg);

  z-index: 9999999;
  pointer-events: none;

  transition: opacity 1s ease-in-out;
}

.spinner {
  margin: 100px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: var(--bg-col);
  height: 100%;
  width: 6px;
  display: inline-block;
  border-radius: 5px;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

.spinner-cont.spinner-loading {
  opacity: 1;
}

.four-zero-four {
  .col-12 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--line);
    min-height: 60vh;
    h1 {
      font-size: 5rem;
      font-weight: 500;
      color: var(--primary);
      padding-bottom: 0;
      span {
        color: var(--line);
      }
    }
    h2 {
      font-size: 1.8rem;
      color: var(--line);
      font-weight: 300;
    }
    p {
      margin-top: 20px;
      color: var(--line);
    }
  }
}
