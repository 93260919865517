.vid-hero {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.vid-cont {
  text-align: center;
  padding-top: calc(var(--gap-l) * 2.5);
  padding-bottom: calc(var(--gap-l) * 2.5);
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
}
.vid-cont h1 {
  font-weight: 900;
  text-transform: uppercase;
  color: #fff;
  font-size: 4rem;
}
.vid-cont p {
  max-width: 600px;
  color: #fff;
}
#videoBG {
  position: absolute;
  z-index: -1;
  width: 100%;
  top: 0;
}
/* .main-row {
	background-color: var(--bg-col);
} */
.main-row:not(.grey-sec),
.main-row:not(footer) {
  background-color: var(--bg-col);
}

@media (min-aspect-ratio: 16/9) {
  #videoBG {
    width: 100%;
    height: auto;
  }
}
@media (max-aspect-ratio: 16/9) {
  #videoBG {
    width: auto;
    height: 100%;
  }
}
@media all and (max-width: 700px) {
  #videoBG {
    min-height: 190%;
  }
  .vid-cont h1 {
    font-size: 3.75rem;
  }
}
